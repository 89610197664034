import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";

import HelpCenterInfo from "../components/help-center/HelpCenterInfo";
import JoinCommunity from "../components/JoinCommunitySection";

import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import HelpCenterContact from "../components/help-center/HelpCenterContact";
import SeoHelpCenter from "../components/SeoHelpCenter";

const HelpCenterPage = ({ data }) => {
  const markdownRemark = data.allMarkdownRemark.edges;

  const [selectedTopic, setSelectedTopic] = useState("Sign in");
  const [currentContent, setCurrentContent] = useState(
    markdownRemark.filter((d) => d.node.frontmatter.category === "Sign in")
  );
  const dropdownOptions = useRef();

  useEffect(() => {
    document.addEventListener("click", function (e) {
      if (!e.target.closest(".dropdown-select-box"))
        if (dropdownOptions.current) dropdownOptions.current.classList.remove("active");
    });
  });

  useEffect(() => {
    switch (selectedTopic) {
      case "Sign in":
        setCurrentContent(markdownRemark.filter((d) => d.node.frontmatter.category === "Sign in"));
        break;
      case "Welcome":
        setCurrentContent(markdownRemark.filter((d) => d.node.frontmatter.category === "Welcome"));
        break;
      case "Profile":
        setCurrentContent(markdownRemark.filter((d) => d.node.frontmatter.category === "Profile"));
        break;
      case "Dashboard":
        setCurrentContent(markdownRemark.filter((d) => d.node.frontmatter.category === "Dashboard"));
        break;
      case "Explore":
        setCurrentContent(markdownRemark.filter((d) => d.node.frontmatter.category === "Explore"));
        break;
      default:
        setCurrentContent(markdownRemark.filter((d) => d.node.frontmatter.category === "Sign in"));
        break;
    }
  }, [selectedTopic, markdownRemark]);

  const renderedMenu = ["Sign in", "Welcome", "Profile", "Dashboard", "Explore"].map(
    (option, index) => {
      return (
        <div key={index} className="text-xl">
          <span
            onKeyDown={() => {
              setSelectedTopic(option);
            }}
            onClick={() => {
              setSelectedTopic(option);
              window.scrollTo(0, 0);
            }}
            role="button"
            tabIndex={0}
            className={`text-base md:text-lg xl:text-xl ${selectedTopic === option && "text-secondary"}`}
          >
            {option}
          </span>
          {index < 2 && <br></br>}
        </div>
      );
    }
  );

  return (
    <>
      <SeoHelpCenter />
      <Header />
      <div className="border-black border-b-2 bg-gray">
        <main className="container py-5 md:py-10 xl:py-20 flex flex-col gap-10">
          <div className="flex flex-col md:flex-row xl:flex-row ">
            <div className="w-full md:w-1/4 xl:w-[38%] flex flex-col gap-2 mb-5 md:mb-0 xl:mb-0 h-fit static md:sticky xl:sticky top-0 md:top-20 xl:top-20">
              <h2 className="text-xl md:text-2xl xl:text-3xl">Popular topics</h2>
              {renderedMenu}
            </div>

            <HelpCenterInfo title={selectedTopic} content={currentContent} id="top" />
          </div>
        </main>
      </div>

      <HelpCenterContact />

      <JoinCommunity />
      <Footer />
    </>
  );
};

export default HelpCenterPage;

export const popularTopicsQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 100
      filter: { frontmatter: { type: { eq: "help-center" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            category
          }
          html
        }
      }
    }
  }
`;
