import React from "react";

import HelpCenterItem from "./HelpCenterItem";

const HelpCenterInfo = (props) => {
  const { content, title } = props;

  const renderedItems = content.map((item, index) => (
    <HelpCenterItem
      key={index}
      content={item.node.html}
      title={item.node.frontmatter.title}
      index={index}
    />
  ));

  return (
    <div className="w-full">
      <h2 className="mb-4 text-2xl md:text-3xl xl:text-4xl">{title}</h2>
      {renderedItems}
    </div>
  );
};

export default HelpCenterInfo;
