import React, { useEffect, useState } from "react";

const HelpCenterItem = (props) => {
  const { content, index, title } = props;
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (index === 0) setShowContent(true);
  }, [index]);

  return (
    <div className="flex flex-col ">
      <div className="border-gray border-b-[1px] py-2 flex items-center justify-between">
        <div
          className="no-outline"
          role="button"
          tabIndex={0}
          onClick={() => setShowContent(!showContent)}
          onKeyDown={() => setShowContent(!showContent)}
        >
          <div
            className="pointer mr-1 text-sm md:text-base xl:text-lg font-mono"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </div>

        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => setShowContent(!showContent)}
          onClick={() => setShowContent(!showContent)}
          className={`pointer accordion-icon h-max ${showContent && "accordion-icon-active"}`}
        >
          <svg width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z"
              fill="#2E3161"
            />
          </svg>
        </div>
      </div>

      {showContent && (
        <div
          className="body2 pt-2"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      )}
    </div>
  );
};

export default HelpCenterItem;
