import React, { useState, useCallback } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

import ThankYouModal from "../common/ThankYouModal";
import { emailTypes } from "../../helpers/constants";
import { upperCaseObjectKeys } from "../../helpers/functions";

const axios = require("axios");

const initialFormInputs = {
  email: "",
  topic: "",
  subject: "",
  fulldesc: "",
};

const topicOptions = [
  { id: "signIn", label: "Sign in" },
  { id: "welcome", label: "Welcome" },
  { id: "profile", label: "Profile" },
  { id: "dashboard", label: "Dashboard" },
  { id: "explore", label: "Explore" },
];

const HelpCenterContact = () => {
  const [showModal, setShowModal] = useState(false);
  const [formInputs, setFormInputs] = useState(initialFormInputs);
  const [errors, setErrors] = useState("");

  const handleChange = (e) => {
    setFormInputs({ ...formInputs, [e.currentTarget.name]: e.target.value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const { email } = formInputs;
      const listFields = upperCaseObjectKeys(formInputs);
      delete listFields.EMAIL;

      // Send email through Mailchimp
      const result = await addToMailchimp(email, { ...listFields, type: emailTypes.contact });

      //check errors
      if (result.result === "error") {
        if (result.msg.includes("is already subscribed")) setShowModal(true);
        else setErrors("Something went wrong. Please try again in about 5 minutes.");
      } else if (result.result === "success") setShowModal(true);

      await axios({
        method: "POST",
        url: process.env.SLACK_WEBHOOK_URL,
        data: {
          text: formInputs.fulldesc,
          username: formInputs.email,
        },
        headers: {
          Accept: "application/json, text/plain, */*, application/x-www-form-urlencoded",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .catch((err) => {
          console.log(err.response.data);
        });

      setFormInputs(initialFormInputs);
    },
    [formInputs]
  );

  return (
    <div className="bg-black text-white">
      <div className="container py-5 md:py-10 xl:py-10 ">
        <h3 className="mb-5 text-3xl">Not the answers you were looking for?</h3>
        <h4 className="mb-0 md:mb-5 xl:mb-5 text-xl">Contact us</h4>

        <div className="flex flex-col md:flex-row xl:flex-row gap-5 md:gap-10 xl:gap-10">
          <form onSubmit={handleSubmit} className="w-full order-last md:order-first xl:order-first text-black">
            <div className="text-sm uppercase mb-2 text-secondary">Email address</div>
            <input
              required
              type="email"
              className={`input input-format w-full mb-5 border-gray border-2 px-5 py-2 rounded-md `}
              name="email"
              placeholder="youremail@domain.com"
              aria-label="email"
              onChange={handleChange}
              value={formInputs.email}
            />

            <div className="text-sm uppercase mb-2 text-secondary">Topic</div>

            <select
              required
              name="topic"
              placeholder="Select an option"
              value={formInputs.topic}
              onChange={handleChange}
              className="w-full mb-5 border-gray border-2 px-5 py-3 rounded-md"
            >
              <option value="" disabled selected className="text-gray">
                Select your option
              </option>

              {topicOptions.map((option) => (
                <option key={option.id} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>

            <div className="text-sm uppercase mb-2 text-secondary">Subject</div>
            <input
              required
              type="text"
              placeholder="What is it about?"
              className={`w-full mb-5 border-gray border-2 px-5 py-2 rounded-md input input-format `}
              name="subject"
              aria-label="subject"
              value={formInputs.subject}
              onChange={handleChange}
            />

            <div className="text-sm uppercase mb-2 text-secondary">Full description</div>
            <textarea
              required
              className={`w-full border-gray border-2 rounded-md px-5 py-2 resize-none `}
              name="fulldesc"
              placeholder="Please be as specific as possible so that we can understand your issue and get back with a solution."
              aria-label="fullDescription"
              rows={5}
              onChange={handleChange}
            />

            <p className="text-xs md:text-sm xl:text-sm text-white">
              You can include files (documents, screen recordings, screenshots, crash logs, etc.) by uploading them to
              any third-party file-sharing service, such as&nbsp;
              <a href="https://www.dropbox.com" rel="noreferrer" target="_blank" className="text-secondary">
                Dropbox
              </a>
              ,&nbsp;
              <a href="https://drive.google.com" rel="noreferrer" target="_blank" className="text-secondary">
                Google Drive
              </a>
              ,&nbsp;
              <a href="https://onedrive.live.com" rel="noreferrer" target="_blank" className="text-secondary">
                Microsoft OneDrive
              </a>
              ,&nbsp;
              <a href="https://wetransfer.com" rel="noreferrer" target="_blank" className="text-secondary">
                WeTransfer
              </a>
              &nbsp; or similar, and pasting the URLs above. Please make sure the correct sharing permission have been
              set. All files sent to us are 100% confidential.
            </p>

            {errors ? <div className="text-red">{errors}</div> : null}

            <button className="w-full bg-primary text-center rounded-md uppercase py-2 mt-4" type="submit">
              Send feedback
            </button>
          </form>

          <div className="mt-5 md:mt-6 xl:mt-6 font-mono">
            <p className="mb-5">
              If you have any questions, possible integrations proposals, experiencing issues, would like to request a
              feature, or looking for a demo, we&apos;re ready to help.
            </p>
            <p>
              Just reach out to us and we&apos;ll respond as soon as we can. Please provide as much information as
              possible.
            </p>
          </div>

          {showModal ? <ThankYouModal open={showModal} close={true} handleClose={() => setShowModal(false)} /> : null}
        </div>
      </div>
    </div>
  );
};

export default HelpCenterContact;
